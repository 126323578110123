import { jsx as _jsx } from "preact/jsx-runtime";
import { useContext } from "preact/compat";
import { Field, Form, Formik } from "formik";
import { formSchema } from "@forms/LookupPostcodeForm/schema";
import { LookupPostcodeCTX } from "@forms/LookupPostcodeForm/context";
import AddressLookup from "@components/fields/AddressLookup";
const initialValues = {
    address: {},
};
function LookupPostcodeForm() {
    const { redirectUrl } = useContext(LookupPostcodeCTX);
    const handleSubmit = (values) => {
        sessionStorage.setItem("lookupAddress", JSON.stringify(values.address));
        if (!redirectUrl) {
            return;
        }
        window.location.href = redirectUrl;
    };
    return (_jsx(Formik, { initialValues: initialValues, onSubmit: handleSubmit, validationSchema: formSchema, children: ({ submitForm }) => (_jsx("div", { id: "step-1", className: "form__page", children: _jsx(Form, { className: "form form--agent-checker", children: _jsx(Field, { name: "address", component: AddressLookup, onCompleted: submitForm }) }) })) }));
}
export default LookupPostcodeForm;
