function convertDataAttributes(attributes) {
    if (!attributes) {
        return {};
    }
    const attributesObj = JSON.parse(attributes);
    const returnedObject = {};
    const keys = Object.keys(attributesObj);
    Object.values(attributesObj).forEach((value, idx) => {
        if (typeof value === "undefined" || value === null) {
            return false;
        }
        const key = keys[idx];
        const val = value;
        try {
            returnedObject[key] = JSON.parse(`${value}`);
        }
        catch (e) {
            returnedObject[key] = val;
        }
        return true;
    });
    return returnedObject;
}
export default convertDataAttributes;
