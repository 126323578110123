import { jsx as _jsx } from "preact/jsx-runtime";
/**
 * Agent Checker React Component
 *
 * https://react.dev/learn/add-react-to-an-existing-project
 */
//import "preact/debug"; // todo/testing: Remove for Production
import { createRoot } from "react-dom/client";
import AgentCheckerForm from "@forms/AgentCheckerForm";
import { AgentCheckerCTX, agentCheckerDefaultContext } from "@forms/AgentCheckerForm/context";
import { LookupPostcodeCTX, lookupPostcodeDefaultContext } from "@forms/LookupPostcodeForm/context";
import LookupPostcodeForm from "@forms/LookupPostcodeForm";
import convertDataAttributes from "@shared/convertDataAttributes";
import { BrowserRouter } from "react-router-dom";
const initAgentCheckerForm = (el) => {
    var _a;
    let initialValues = {
        postcode: "",
        lookupAddress: {},
        numberOfBedrooms: "",
        estimatedValue: "",
        periodToList: "",
        fullName: "",
        emailAddress: "",
        phone: "",
        agentID: 0,
        address: {},
    };
    // Check and get sessionStorage for initial values
    if (sessionStorage.getItem("lookupAddress")) {
        const lookupAddress = JSON.parse(sessionStorage.getItem("lookupAddress"));
        if (lookupAddress && lookupAddress.postcode) {
            initialValues = Object.assign(Object.assign({}, initialValues), { lookupAddress: lookupAddress, address: lookupAddress, postcode: lookupAddress.postcode });
        }
        sessionStorage.removeItem("lookupAddress");
    }
    const basename = window.location.pathname;
    const formattedDataAttrs = convertDataAttributes((_a = el.dataset.args) !== null && _a !== void 0 ? _a : null);
    //console.debug({ test: { ...agentCheckerDefaultContext, ...formattedDataAttrs } });
    const element = (_jsx(BrowserRouter, { basename: basename, children: _jsx(AgentCheckerCTX.Provider, { value: Object.assign(Object.assign(Object.assign({}, agentCheckerDefaultContext), formattedDataAttrs), { "container": el }), children: _jsx(AgentCheckerForm, { initialValues: initialValues }) }) }));
    const root = createRoot(el);
    root.render(element);
};
const initLookupPostcodeForm = (el) => {
    var _a;
    const formattedDataAttrs = convertDataAttributes((_a = el.dataset.args) !== null && _a !== void 0 ? _a : null);
    const element = (_jsx(LookupPostcodeCTX.Provider, { value: Object.assign(Object.assign(Object.assign({}, lookupPostcodeDefaultContext), formattedDataAttrs), { "container": el }), children: _jsx(LookupPostcodeForm, {}) }));
    const root = createRoot(el);
    root.render(element);
};
class AgentChecker {
    constructor(element) {
        this.element = element;
        const useLookupPostcodeForm = this.element.classList.contains('react--lookup-postcode');
        if (useLookupPostcodeForm) {
            initLookupPostcodeForm(element);
        }
        else {
            initAgentCheckerForm(element);
        }
    }
}
export default AgentChecker;
